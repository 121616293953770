<template>
  <me-form class="me-grid-column-4" :rules="rules" ref="form">
    <me-label width="300px" title="单据编号" prop="sbillno">
      <me-input clearable v-model="form.sbillno" />
    </me-label>
    <me-label width="300px" title="收票会员" prop="sbuyermemberid">
      <me-input clearable v-model="form.sbuyermemberid" />
    </me-label>
    <me-label width="300px" title="开票会员" prop="ssellermemberid">
      <me-input clearable v-model="form.ssellermemberid" />
    </me-label>
    <me-label width="300px" title="发票类型" prop="itype">
      <me-input clearable v-model="form.itype" />
    </me-label>
    <me-label width="300px" title="单据类型" prop="ibilltype">
      <me-input clearable v-model="form.ibilltype" />
    </me-label>
    <me-label width="300px" title="开票金额" prop="finvoiceamount">
      <me-input clearable v-model="form.finvoiceamount" />
    </me-label>
  </me-form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      formDefault: {
        sguid: '',
        sbillno: '',
        sbuyermemberid: '',
        ssellermemberid: '',
        itype: '',
        ibilltype: '',
        finvoiceamount: ''
      },
      form: { ...this.formDefault },
      rules: {
        sguid: [
          { required: true, message: '主键不能为空' }
        ],
        sbillno: [
          { required: true, message: '单据编号不能为空' }
        ],
        sbuyermemberid: [
          { required: true, message: '收票会员不能为空' }
        ],
        ssellermemberid: [
          { required: true, message: '开票会员不能为空' }
        ],
        itype: [
          { required: true, message: '发票类型不能为空' }
        ],
        ibilltype: [
          { required: true, message: '单据类型不能为空' }
        ],
        finvoiceamount: [
          { required: true, message: '开票金额不能为空' }
        ]
      }
    }
  },
  methods: {
    ...mapActions([ 'selectReceiptinfoByPrimary', 'insertReceiptinfo' ]),
    async save () {
      await this.$refs.form.validate()
      await this.insertReceiptinfo(this.form)
    },
    async refreshData ({ sguid }) {
      this.form = { ...this.formDefault }
      if (this.$tools.notBlank(sguid)) {
        const data = await this.selectReceiptinfoByPrimary({ key: sguid })
        this.$tools.assign(this.form, { ...data, sguid: undefined })
      }
    },
    reset () {
      this.$refs.form.reset()
    }
  }
}
</script>
